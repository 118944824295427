import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { alert } from 'devextreme/ui/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { AppConfig } from '../app.config';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import validationEngine from 'devextreme/ui/validation_engine';
import {  GlobaleDatenService } from '../services/globaledaten.serice';
import * as funktionen from './../globals/functions';
import { AppRouteChangeService } from '../services/app-route-change.service';
import { DxValidationGroupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariable } from '../globals/globals';
import { enuKunde } from '../app-config.model';
import { Datenschutz } from '../services/user.interface';

export class LoginUpgrade {
  email: string;
  newsletter: boolean = true;
  datenschutz: boolean = false;

  constructor(

    ) {

    }
}

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginbuttonOptions: any;
  loginfieldsOptions: { onFocusIn: () => void; };
  userfieldOptions: any;
  passwordfieldOptions: any;
  kurs_id: any;
  reg: any;
  infotext: string;
  infoheader: string;

  benutzerPattern: string = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ.-@_';
  // benutzerPattern:any= /^abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ\.\-@_/;

  anmeldenButtonOptions: any;

  upgradeVisible: boolean = false;
  newsletter: boolean = true;
  datenschutz: boolean = false;
  upgradeButtonOptions: any;
  cancelButtonOptions: any;

  buttonInfoDatenschutz: any;
  buttonInfoNewsletter: any;

  daten: LoginUpgrade;

  enuKunde = enuKunde;
  
  @ViewChild('valGroupDatenschutz', { static: false }) valGroupDatenschutz: DxValidationGroupComponent;

  menuLarge: boolean;
  popupWidth: number;
  popupHeight: number;

  erweitertesLogin = AppConfig.kunde_settings.erweitertesLogin;

  mitnewsletter = AppConfig.kunde_settings.newsletter;
  nurgdsgvo: any;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    // private deviceService: DeviceDetectorService,
    private router: Router,
    public route: ActivatedRoute,

    public gdService: GlobaleDatenService,
    private ngZone: NgZone,
    public appRouteChangeService: AppRouteChangeService,
    public translate: TranslateService
  ) {
    this.detectDevice();
    if(authService.loggedIn()) {
      this.router.navigate(['/profil']);
    }
    // this.einstellungen.SetDefault();



    this.sProduktivText = `Sie melden sich am –  Produktivsystem – der Bergwacht Bayern an.
    Sämtliche Dateneingaben und –änderungen in diesem System
    führen somit zu einer Änderung der Produktivdaten.'`;
    this.sTestText = `Sie melden sich am –  Testsystem – der Bergwacht Bayern an.
    Sämtliche Dateneingaben und –änderungen in diesem System
    werden nur temporär gespeichert!.`;

    this.passwordMode = 'password';

    if (AppConfig.settings.testversion) {
      this.backgroundImage = './../../assets/img/bergkette2_testsystem.png';
      this.bwb_system = 'bwbsystem_test';
    } else {
      this.backgroundImage = './../../assets/img/bergkette2.png';
      this.bwb_system = 'bwbsystem_prod';
    }

    this.daten = new LoginUpgrade();

    this.userfieldOptions = {
      onKeyDown(e){
        // if(gdService.istKunde(enuKunde.AVS)) {
          return gdService.onKeyDown(e);
        // }
      },
      onFocusIn: () => {
        // this.captchaVisible = true;
      },

    };

    this.okLogin = this.okLogin.bind(this);

    this.onUpgrade = this.onUpgrade.bind(this);
    this.onUpgradeAbbrechen = this.onUpgradeAbbrechen.bind(this);
    // this.passwordOptions = this.onUpgradeAbbrechen.bind(this);

    this.passwordfieldOptions = this.passwordOptions();

    this.benutzernameKontrolle = this.benutzernameKontrolle.bind(this);

    this.onJetztRegistrieren = this.onJetztRegistrieren.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    
    this.getPaddingLeftInner = this.getPaddingLeftInner.bind(this);
    this.getPaddingLeftOuter = this.getPaddingLeftOuter.bind(this);
    this.getPaddingRightOuter = this.getPaddingRightOuter.bind(this);
    this.getMaxWidth = this.getMaxWidth.bind(this);
  }

  passwordOptions() {
    return {
      mode: this.passwordMode,
      buttons: [{
          name: 'password',
          location: 'after',
          options: {
              type: 'default',
              height: '37px',
              elementAttr: {
                class: 'password',
              },
              icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
              onClick: () => {
                this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
                this.passwordfieldOptions = this.passwordOptions();
              }
          }
      }],
      onEnterKey: () => {
        this.onSignin();
      },
    }
}


  get backgroundImageUrl() {
    if (this.backgroundImage) {
      return 'url("${this.backgroundImage}")';
    }

    return null;
  }
  myForm: UntypedFormGroup;
  public benutzername: AbstractControl;
  public password: AbstractControl;

  public loadingVisible = false;

  access_token: string;
  refresh_token: string;
  public myAppConfig = AppConfig;

  public datenschutzVisible = false;
  public dsgvo = false;
  public deviceInfo: any;
  public iexplorer: boolean;

  public bergwachtwechselVisible = false;
  public infoProduktivVisible = false;

  dsgvoItem: Datenschutz;
  passwordMode: string;

  public sTitel: string;
  public sProduktivTitel = 'Achtung Produktivbetrieb!';
  public sTestTitel = 'Achtung Testsystem!';

  public sText: string;
  public sProduktivText = 'Achtung Produktivbetrieb!';
  public sTestText = 'Achtung Testsystem!';

  backgroundImage = './../../assets/img/bergkette2.png';
  bwb_system = 'bwbsystem_test';


    detectDevice() {
      // this.deviceInfo = this.deviceService.getDeviceInfo();
      // const isMobile = this.deviceService.isMobile();
      // const isTablet = this.deviceService.isTablet();
      // const isDesktopDevice = this.deviceService.isDesktop();
      // console.log(this.deviceInfo);
      // console.log(isMobile);
      // console.log(isTablet);
      // console.log(isDesktopDevice);
      // console.log(this.deviceInfo.browser);
      // if (this.deviceInfo.browser === 'ie' || this.deviceInfo.browser === 'IE') { this.iexplorer = true; }

    }

  removeFilterValue(storageKey) {
    // console.log('removeFilterValue', storageKey);
    const storageItem = JSON.parse(localStorage.getItem(storageKey));
    if (storageItem != null) {
      for (const item of storageItem.columns) {
        if (item.hasOwnProperty('filterValues')) {
          delete item.filterValues;
        } else if (item.hasOwnProperty('filterValue')) {
          delete item.filterValue;
        }
      }
      if (storageItem.filterValue) {
        delete storageItem.filterValue;
      }
      if (storageItem.pageSize) {
        delete storageItem.pageSize;
      }
      if (storageItem.pageIndex) {
        // console.log('removeFilterValue pageIndex', storageItem.pageIndex);
        storageItem.pageIndex = 0;
      }
      localStorage.setItem(storageKey, JSON.stringify(storageItem));
    }
  }
  // removeFilterValues() {
  //   const grids = EnumValues.getNames(enuGridLayouts);
  //   for ( const g of grids) {
  //     this.removeFilterValue(g);
  //   }
  // }
  public onSignin() {
    // console.log('onSignin ');
    // this.gdService.Reset();
    let localversion = null;
    if(localStorage.getItem('Version')) localversion = localStorage.getItem('Version');
    if ((localversion <= GlobalVariable['version'])) {
      localStorage.setItem('Version', GlobalVariable['version']);
      this.loadingVisible = true;
      try {

        this.authService.login( { benutzername: this.myForm['benutzername'].trim(), password: this.myForm['password'], ispasswordencoded: false})
        .then((res) => {
          // console.log('onSignin 1');
          this.loadingVisible = false;
          if (!res['website'] || res['dsgvo']) {
            this.dsgvoItem = res['dsgvo'];
            this.daten.email = res['email'];
            this.nurgdsgvo = res['website'];
            this.upgradeVisible = true;

            this.popupWidth = 650;
            if(this.mitnewsletter && !this.nurgdsgvo) {
              this.popupHeight = 500;
            } else {
              this.popupHeight = 400;
            }
            if (this.popupWidth >=  window.innerWidth) {
              this.popupWidth = window.innerWidth - 25;
            }
            if (750 >=  window.innerHeight) {
              this.popupHeight = window.innerHeight - 50;
        
            }
        
          } else {
            this.onLogin(res);
          }
        })
        .catch((error) => {
          // console.log('onSignin 2', error);
          this.loadingVisible = false;
          if (error instanceof HttpErrorResponse) {
    
            const e =  error.error;
            // console.log('Fehler', e);
            if (e && e.auth) {
              if (e.auth[0] === 'Too many attempts!') {
                alert( this.translate.instant('LOGIN.UNGUELTIGE_ZUGRIFFE_BENUTZER'),  this.translate.instant('LOGIN.FEHLER'));
              } else if (e.auth[0] === 'Bad username or password') {
                alert(this.translate.instant('LOGIN.EMAIL_ODER_KENNWORT_FALSCH'), this.translate.instant('LOGIN.FEHLER'));
              } else if (e.auth[0] === 'AppGesperrt') {
                // eslint-disable-next-line max-len
                alert(this.translate.instant('LOGIN.AVSOFFICE_WARTUNGSARBEITEN_NICHT_AKTIV'),  this.translate.instant('LOGIN.FEHLER'));
              } else {
                alert(this.translate.instant('LOGIN.EINGABEN_NICHT_KORREK')+'<br>'+this.translate.instant('LOGIN.EINGABEN_UEBERPRUEFE'), this.translate.instant('LOGIN.FEHLER'));
              }
            } else if (error.status === 400 && error.statusText === 'AppGesperrt') {
              alert(this.translate.instant('LOGIN.AVSOFFICE_WARTUNGSARBEITEN_NICHT_AKTIV'), this.translate.instant('LOGIN.HINWEIS'));
            } else if (error.status === 400 && error.statusText === 'Dieser Benutzer ist keiner Person zugeordnet.') {
              alert(error.statusText, this.translate.instant('LOGIN.HINWEIS'));
            } else {
              alert(this.translate.instant('LOGIN.EINGABEN_NICHT_KORREK')+'<br>' + this.translate.instant('LOGIN.EINGABEN_UEBERPRUEFE'), this.translate.instant('LOGIN.FEHLER'));
            }
          } else {
            // console.log('Fehler', error);
            if (error.status === 400 && error.statusText === 'AppGesperrt') {
              alert(this.translate.instant('LOGIN.AVSOFFICE_WARTUNGSARBEITEN_NICHT_AKTIV'), this.translate.instant('LOGIN.HINWEIS'));
            } else {
              alert(this.translate.instant('LOGIN.FEHLER_ZUGRIFF_DATENBANK'), this.translate.instant('LOGIN.FEHLER'));
            }
          }
        }).finally(() => {
          this.loadingVisible = false;
    
        });
      } catch(error) {
        this.loadingVisible = false;
      }
    } else {
      alert( this.translate.instant('VERSIONKONFLIKT'), this.translate.instant('LOGIN.FEHLER')).then(() => {
        window.location.reload();
      });
    }
  }

  private onLogin(res){
    this.loadingVisible = false;
    this.gdService.anrede = res['anrede'];
    this.gdService.vorname = res['vorname'];
    this.gdService.firmenname = res['firmenname'];
    this.gdService.nachname = res['nachname'];
    this.gdService.mitglied = res['mitglied'];
    this.gdService.mitglied_id = res['mgd_id'];
    this.gdService.benutzer_id = res['benutzer_id'];
    localStorage.setItem('mgd_id', this.gdService.mitglied_id);
    if(this.gdService.vorname) {
      localStorage.setItem('mgd_vorname', this.gdService.vorname);
    } else if(this.gdService.firmenname) {
      localStorage.setItem('mgd_vorname', this.gdService.firmenname);
    } else {
      localStorage.setItem('mgd_vorname', '');
    }
    localStorage.setItem('benutzer_id', this.gdService.benutzer_id);
    this.gdService.newsletter = res['newsletter'];
    this.gdService.dsgvowebsite = res['dsgvowebsite'];
    this.gdService.dsgvonewsletter = res['dsgvonewsletter'];
    this.gdService.loadMitglied().then((data) => {
      this.gdService.setCookies();
    });

    let url = this.route.snapshot.queryParamMap.get('returnUrl');
    if (funktionen.isEmptyString(url)) {
      this.ngZone.run(() => this.router.navigateByUrl('/profil'));
    } else {
      // const pos = url.indexOf('?');

      // let paramstr = '';
      // const queryParams = {};
      // if (pos > 0) {
      //   paramstr = url.substring(pos + 1);
      //   const params = paramstr.split('&');
      //   for (const item of params) {
      //     const pos1 = item.indexOf('=');
      //     queryParams[item.substring(0, pos1)] = item.substring(pos1 + 1);
      //   }
      //   url = url.substring(0, pos) ;
      // }
      if (funktionen.isEmptyString(url)) {
        this.ngZone.run(() => this.router.navigateByUrl('/profil'));
      } else {
        if (url.includes('https://alpenverein.it') || url.includes(window.location.origin)) {
          window.open(url, '_self');
        } else {
          this.ngZone.run(() => this.router.navigateByUrl(url)).catch((error) => {
            this.ngZone.run(() => this.router.navigateByUrl('/profil'));
          });
        }
      }
    }
    return;
  }

  onUpgrade(e) {
    // const groupobject = e.validationGroup.group;
    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {
      this.loadingVisible = true;

      if(this.nurgdsgvo) {
        this.authService.putUrl('/portal/benutzeracceptdsgvo'
          , { benutzername : this.myForm['benutzername'], 
          locale :localStorage.getItem('locale'),
          vertragdokument_id: this.dsgvoItem.vertragdokument_id}).then( (data) => {
          this.upgradeVisible = false;
          this.loadingVisible = false;
          if (data === '') {
            this.onSignin();
          }
        }).catch((error) => {
          this.loadingVisible = false;
          alert(error.statusText, this.translate.instant('REGISTER.FEHLER'));
        });
      } else {
        this.authService.putUrl('/portal/addbenutzerwebsite_frombenutzeroffice'
          , { benutzername : this.myForm['benutzername'],  password : this.myForm['password'], 
          email : this.daten['email'], 
          newsletter : this.daten['newsletter'], 
          locale :localStorage.getItem('locale'),
          vertragdokument_id: this.dsgvoItem.vertragdokument_id}).then( (data) => {
          this.upgradeVisible = false;
          this.loadingVisible = false;
          if (data === '') {
            this.onSignin();
          }
        }).catch((error) => {
          this.loadingVisible = false;
          alert(error.statusText, this.translate.instant('REGISTER.FEHLER'));
        });
      }
    }

  }

  benutzernameKontrolle(e) {
    if (e) {
      for (const val of e.value) {
        if (this.benutzerPattern.indexOf(val) === -1) {
          return false;
        }
      }
      return true;
    }
  }

  async openDSGVO(item){
    console.log("item: ", item)
    let dsgvoVorhanden = await funktionen.openDSGVO('_blank', this.authService, item.typ, this.dsgvoItem, this.myForm['benutzername']);
    if(!dsgvoVorhanden){
      this.upgradeVisible = false;
      this.onSignin();
    }
  }


  onUpgradeAbbrechen(e) {
    this.upgradeVisible = false;
  }

  public onReset() {
    this.ngZone.run(() => this.router.navigate(['/passwordreset']));
  }

  ngOnInit(): any {
    // console.log(JSON.stringify(environment));
    if (AppConfig.settings.testversion) {
      this.sText = this.sTestText;
      this.sTitel = this.sTestTitel;
    } else {
      this.sText = this.sProduktivText;
      this.sTitel = this.sProduktivTitel;
    }
    this.myForm = this.fb.group({
        benutzername: [environment.benutzername, Validators.required],
        password: [environment.password, Validators.required],
    });
    this.benutzername = this.myForm.controls.benutzername;
    this.password = this.myForm.controls.password;


    this.anmeldenButtonOptions = {
      text: this.translate.instant('LOGIN.ANMELDEN_KLEIN'),
      type: 'success',
      template: 'buttonTemplate',
      useSubmitBehavior: false,
      onClick: (e) => {
        this.okLogin(e);
      }
    };
    this.cancelButtonOptions = {
      icon: 'clear',
      text: this.translate.instant('LOGIN.ABRRECHEN'),
      type: 'button',
      useSubmitBehavior: false,
      onClick: (e) => {
        this.onUpgradeAbbrechen(e);
      }
    };


    this.getURLQuerys();

  }
  onCancel() {
    throw new Error('Method not implemented.');
  }

  getURLQuerys() {
    // Registrierungsinfo anzeigen reg=1
    if (this.route.snapshot.queryParams.reg != null) {
      this.reg = this.route.snapshot.queryParams.reg;
    } else {
      this.reg = 0;
    }
    // this.reg='1';
    // Kursbuchung
    this.kurs_id = this.route.snapshot.queryParams.kurs_id;
    if (this.kurs_id) {
      this.infoheader = 'Kursanmeldung';
      this.infotext = 'Bitte melde dich an um diesen Kurs zu buchen';
    }
  }

  datenschutzComparison() {
    return true;
  }

  public onEinwilligen() {
    this.authService.dsgvo = 'ok';
    this.authService.put('/rechte/dsgvo', null).then(
      () => {
      }
    ).catch((error) => {
      console.log('onSignin catch 2', error);
    });

    if (!AppConfig.settings.showinfo) {
      this.AppLoad();
    } else {
      this.infoProduktivVisible = true;
    }
  }

  public AppLoad() {
  }

  public registerNew() {
    this.ngZone.run(() => this.router.navigate(['/register']));
  }

  public onInfoProduktivVerstanden() {
    this.AppLoad();
  }

  public onInfoProduktivAbbrechen() {
    this.authService.logout(false);
  }
  public onAbbrechen() {
    this.authService.logout(false);
  }

  public okLogin(e){
    console.log('login');
    // this.loadingVisible = true;
    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {
    this.onSignin();
    }
    // this.loadingVisible = false;

  }

  handleToken(e) {
   console.log('Token:', e);
  }


  onJetztRegistrieren(e) {
    console.log('onJetztRegistrieren', e);
    // this.router.navigate(['/register']);
    // this.loadingVisible = true;

    this.authService.getUrl('/portal/servergesperrt').then((servergesperrt) => {
      if (!servergesperrt) {
        this.router.navigate(['/register']);
      } else {
        notify(this.translate.instant('SERVERGESPERRT'), 'error', this.gdService.dauerMeldungServerGesperrt);
      }
    }).catch((error) => {
      console.log('Fehler', error);
      notify('Eingaben wurden nicht gespeichert', 'error', 1000);
    });
    return false;
  }

  onResetPassword(e) {
    console.log('onResetPassword', e);
    this.router.navigate(['/resetpassword']);
    return false;
  }

  onClickSprache(langToSet: string) {
    this.translate.use(langToSet);
    localStorage.setItem('locale', langToSet);

    switch(langToSet) {
      case 'de':
        var de = document.getElementById("de");
        de.classList.remove('other-button-class');
        var it = document.getElementById("it");
        it.classList.add('other-button-class');
        var en = document.getElementById("en");
        en.classList.add('other-button-class');
        break;
      case 'it':
        var de = document.getElementById("de");
        de.classList.add('other-button-class');
        var it = document.getElementById("it");
        it.classList.remove('other-button-class');
        var en = document.getElementById("en");
        en.classList.add('other-button-class');
        break;
      case 'en':
        var de = document.getElementById("de");
        de.classList.add('other-button-class');
        var it = document.getElementById("it");
        it.classList.add('other-button-class');
        var en = document.getElementById("en");
        en.classList.remove('other-button-class');
        break;
    }
    // window.location.reload();
    this.reloadData();
  }

  reloadData(){

  }
  getPaddingLeftOuter() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '0px';
      case enuKunde.KFS: 
        return '50px';
      case enuKunde.Standard: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '250px';
        }
      case enuKunde.Template: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '50px';
        }
    }

  }

  getPaddingRightOuter() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '0px';
      case enuKunde.KFS: 
        return '0px';
      case enuKunde.Standard: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '250px';
        }
      case enuKunde.Template: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '50px';
        }
    }
  }

  getPaddingLeftInner() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '0px';
      case enuKunde.KFS: 
      case enuKunde.Standard: 
      case enuKunde.Template: 
      if(this.gdService.isMobileDevice) {
        return '20px';
      } else {
        return '50px';
      }
    }

  }

  getMaxWidth() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '450px';
      case enuKunde.KFS: 
      case enuKunde.Standard: 
      case enuKunde.Template: 
        return '390px';
    }

  }

  getDisplay(){
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
      case enuKunde.KFS: 
        return 'inherit';
      case enuKunde.Standard: 
      case enuKunde.Template: 
        return 'inline-flex';
    }
  }

}
