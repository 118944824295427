import { Injectable } from '@angular/core';
import { Router, RoutesRecognized, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { enuKunde } from '../app-config.model';
import { AppConfig } from '../app.config';
import { AuthService } from './auth.service';
import { GlobaleDatenService, GlobalVariable } from './globaledaten.serice';

@Injectable({
 providedIn: 'root'
})
export class AppRouteChangeService {

  public jahrCopy: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    protected authservice: AuthService,
    protected gdService: GlobaleDatenService,
    ) {
    router.events.subscribe((event) => {
     this.RouterEvent(event);
    });
    this.jahrCopy = GlobalVariable['jahrCopy'];
    
  }

  RouterEvent(event) {
    let title: string;
    if (event instanceof NavigationStart) {
      let pathname = window.location.pathname;
      if(AppConfig.settings.sperrung) {
        if (!pathname.includes('/sperrung')) {
          window.location.replace(window.location.origin + '/sperrung');
        } 
      }
    }
    if (event instanceof NavigationEnd) {
      // console.log('NavigationStart');
      this.gdService.getVereinsdaten().then(
        (data) => {
          switch (event.url) {
            case '/start':
              title = 'Start';
              break;
            case '/anmelden':
              title = 'Registrieren und Anmelden';
              break;
            case '/mitgliedneu':
              // title = 'Mitglied werden';
              title = this.translate.instant('MITGLIEDWERDEN.MITGLIEDWERDEN');
              break;
            case '/register':
              // title = 'Neu registrieren';
              title = this.translate.instant('REGISTER.NEUREGISTRIEREN');
              break;
            case '/login':
              title = 'Anmelden';
              break;
            case '/resetpassword':
              if(this.authservice.loggedIn()) title = this.translate.instant('RESETPASSWORD.PASSWORT_VERGESSEN');
              if(!this.authservice.loggedIn()) title = this.translate.instant('LOGIN.BENUTZERNAME_PASSWORT_VERGESSEN');
              break;
            case '/newsletter':
              title = 'Newsletter';
              break;
            case '/veranstaltungen':
              title = 'Veranstaltungen';
              break;
            case '/veranstaltungen/1':
              title = 'Kurse';
              break;
            case '/veranstaltungen/2':
              title = 'Tätigkeiten';
              break;
            case '/kursdetail':
              title = 'Kursdetail';
              break;
            case '/kursbuchen':
              title = 'Kurs Buchen';
              break;
            case '/profil':
              title = 'Profil';
              break;
            default:
              switch(AppConfig.settings.kunde) {
                case enuKunde.AVS:
                  title = 'Alpenverein Südtirol';
                  break;
                case enuKunde.Standard:
                case enuKunde.Template:
                  title = this.gdService.vereinsdaten.name;
                  break;
              }
              break;
          }
          if(this.gdService.istKunde(enuKunde.AVS)) {
            var titel_header = document.querySelector('#titel_header');
            if (titel_header) {
              document.querySelector('#titel_header').innerHTML = title;
            }
            document.title = title + ' - Alpenverein Südtirol';
          } else if(this.gdService.istKunde(enuKunde.Standard) || this.gdService.istKunde(enuKunde.Template)) {
            if(title.localeCompare(this.gdService.vereinsdaten.name) !== 0){
              document.title = title + ' - ' + this.gdService.vereinsdaten.name;
            } else {
              document.title = title;
            }
          }
          // return;
    
          const mitgliedsid = localStorage.getItem('mgd_id');
          let mitglied = false;
          let expdate: Date;
    
          if (mitgliedsid === null) {
            mitglied = false;
          } else {
            if (localStorage.getItem('expdate')) {
              try {
                expdate =  new Date(JSON.parse( localStorage.getItem('expdate')));
                const aktDate = new Date();
                if (aktDate < expdate) {
                  mitglied = true;
                } else {
                  mitglied = false;
                }
              } catch (error) {
                mitglied = false;
              }
            }
          }
    
          // console.log('mgd_id', mitgliedsid, mitglied);
          if(this.gdService.istKunde(enuKunde.AVS)) {
            if (!mitglied) {
              const menu = document.querySelector(".avs-login-username");
              if (menu) {
                menu.classList.remove("avs-login-active");
                document.querySelector('.avs-login-username a').innerHTML = 'mein.alpenverein';
              }
            } else {
              const menu = document.querySelector(".avs-login-username");
              if (menu) {
                menu.classList.add("avs-login-active");
                document.querySelector('.avs-login-username a').innerHTML = 'Hallo, ' + localStorage.getItem('mgd_vorname') + "!";
              }
            }
          }
          if(this.gdService.istKunde(enuKunde.Standard)) {

            if (!mitglied) {
              const menu = document.querySelector(".dropdown-content");
              menu.classList.remove("login-active");
    
              document.querySelector('.dropbtn').innerHTML =  'mein.verein';
              let element: HTMLElement = document.getElementsByClassName('dropbtn')[0] as HTMLElement;
              element.onclick = () => { this.router.navigateByUrl('/profil') };

            } else {
              const menu = document.querySelector(".dropdown-content");
              menu.classList.add("login-active");
              document.querySelector('.dropbtn').innerHTML = 'Hallo, ' + localStorage.getItem('mgd_vorname') + "!";
              let element: HTMLElement = document.getElementsByClassName('dropbtn')[0] as HTMLElement;
              element.onclick = () => { };
            }
            document.querySelector('.name').innerHTML = this.gdService.vereinsdaten.name;
            document.querySelector('.strasse').innerHTML = this.gdService.vereinsdaten.strasse + ' ' + this.gdService.vereinsdaten.hausnr;
            document.querySelector('.ort').innerHTML = this.gdService.vereinsdaten.nation + ' - ' + this.gdService.vereinsdaten.plz + ' ' + this.gdService.vereinsdaten.ort;
            if(this.gdService.vereinsdaten.telefon) document.querySelector('.telefon').innerHTML = 'Tel.: <a  style="color: white" class="link_text" href="tel:' + this.gdService.vereinsdaten.telefon + '" target="_blank">' + this.gdService.vereinsdaten.telefon+ '<a>';
            document.querySelector('.email').innerHTML = 'E-Mail: <a style="color: white" class="link_text" href="mailto:' + this.gdService.vereinsdaten.email + '" target="_blank">' + this.gdService.vereinsdaten.email + '<a>';
            document.querySelector('.copyright').innerHTML =  '© ' + this.jahrCopy + ' TuGA GmbH. Alle Rechte vorbehalten.<img  style="float: right; padding-left: 5px;" src="' + AppConfig.settings.myBase + '/assets/img/tugalogo16.png" alt="">';
            document.querySelector('.kundenname').innerHTML = this.gdService.vereinsdaten.name;
    
          }
        }
      );

    }
  }

  reloadData(){
    console.log("est");
    let title: string;
    switch (this.router.url) {
      case '/start':
        title = 'Start';
        break;
      case '/anmelden':
        title = 'Registrieren und Anmelden';
        break;
      case '/mitgliedneu':
        title = this.translate.instant('MITGLIEDWERDEN.MITGLIEDWERDEN');
        break;
      case '/register':
        title = this.translate.instant('REGISTER.NEUREGISTRIEREN');
        break;
      case '/login':
        title = 'Anmelden';
        break;
      case '/resetpassword':
        if(this.authservice.loggedIn()) title = this.translate.instant('RESETPASSWORD.PASSWORT_VERGESSEN');
        if(!this.authservice.loggedIn()) title = this.translate.instant('LOGIN.BENUTZERNAME_PASSWORT_VERGESSEN');
        break;
      case '/newsletter':
        title = 'Newsletter';
        break;
      case '/kurseundveranstaltungen':
        title = 'Kurse & Veranstaltungen';
        break;
      case '/kursdetail':
        title = 'Kursdetail';
        break;
      case '/kursbuchen':
        title = 'Kurs Buchen';
        break;
      case '/profil':
        title = 'Profil';
        break;
      default:
        switch(AppConfig.settings.kunde) {
          case enuKunde.AVS:
            title = 'Alpenverein Südtirol';
            break;
          case enuKunde.Standard:
          case enuKunde.Template:
            title = '';
            break;
        }
        break;
    }
    if(this.gdService.istKunde(enuKunde.AVS)) {
      document.querySelector('#titel_header').innerHTML = title;
      document.title = title + ' - Alpenverein Südtirol';
    }
  }
}