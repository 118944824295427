import { UUID } from 'angular2-uuid';
import { enuAnrede, enuGeschlecht } from '../globals/enumerationen';

export class Kontakt {
  public id: string;
  public anrede: any;
  public geschlecht: enuGeschlecht;
  public nationalitaet: string;
  public nationalitaet_name: string;
  public nationalitaet_kuerzel: string;
  public land_id: string;
  public land: string;
  public nation: string;
  public nationalitaet_id: string;
  public plz: string;
  public ort_id: string;
  public ort: string;
  public strasse: string;
  public weiler: string;
  public hausnr: string;
  public provinz: string;
  public titel: string;
  public vorname: string;
  public nachname: string;
  public verheirateterName: string;
  public gebdatum: Date;
  public gebdatum_text: string;
  public gebort: string;
  public geburtsort: string;
  public gebort_name: string;
  public steuernummer: string;
  public sprache_id: string;
  public sprache: string;
  public email: string;
  public telefon: string;
  public mitgliedsnr: string;
  public kartennr: string;
  public nummer: string;
  public istGeprueftesMitglied: boolean;
  public steuergeprueft: boolean;
  public steuerfehlerTxt: string;
  public emailgeprüft: boolean;
  public emailfehlerTxt: string;
  public erzberechtigte: Kontakt[];

  public funktionen: any[];
  public funktionenAktiv: any[];
  public funktionenInaktiv: any[];
  public keineFunktionenTxt: any;

  public qualifikationen: any[];
  public qualifikationenAktiv: any[];
  public qualifikationenInaktiv: any[];
  constructor(
  ) {
    // this.id = UUID.UUID();
    this.anrede = null;
    this.id = null;
    // this.nationalitaet = 'Italien';
    this.istGeprueftesMitglied = false;
    this.erzberechtigte = new Array();
    this.geschlecht = null;

    this.funktionen = new Array();
    this.funktionenAktiv = new Array();
    this.funktionenInaktiv = new Array();

    this.qualifikationen = new Array();
    this.qualifikationenAktiv = new Array();
    this.qualifikationenInaktiv = new Array();

  }

}


