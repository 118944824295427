import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { enuKursTyp, enuProfilMenu } from '../globals/enumerationen';
import { AuthService } from '../services/auth.service';
import { DataUrl } from '../services/dataurls';
import { GlobaleDatenService } from '../services/globaledaten.serice';
import { Profil} from './profil.model';
import { TranslateService } from '@ngx-translate/core';

export class DataUrlProfil extends DataUrl {
    url: string;
    constructor() {
      super();
      this.url = '/portal/mitglied';
    }
  }
export class Profilmenu {
    id: enuProfilMenu;
    name: string;
    icon?: string;
    disabled?: boolean;
    edit?: boolean;
    items?: Profilmenu[];
    fields?: any;
    extra?: any;
    visible?: boolean;
}

@Injectable()
export class ProfilService extends BaseService {
    profil: Profil;
    // datenListe: Array<any>;

  constructor(
    public authservice: AuthService,
    public gdService: GlobaleDatenService,
    public dataurl: DataUrlProfil,
    public translate: TranslateService
  ) {
      super(authservice, dataurl);
    // this.ds = new DataSource({
    //   paginate: true,
    //   pageSize: 10
    //  });
      this.profil = new Profil();
    // this.datenListe = new Array();
   
  }

    // get item(): Profil {
    //     return this.profil as Profil;
    // }

    getProfil() {
        return this.profil;
    }

  

    getProfilMenuTranslated(){
        const profilmenuMitglied: Profilmenu[] = [
            {
                id: enuProfilMenu.PersoenlicheDaten,
                name: this.translate.instant('PROFIL.PERSOENLICHEDATEN'),
                disabled: false,
                visible: false,
                fields: {
                        name: {
                            label: this.translate.instant('PROFIL.NAME'),
                            edit: true,
                            selected: false,
                            subfields: {
                                headerlabel: this.translate.instant('PROFIL.NAMEAENDERN'),
                                anrede: {label: this.translate.instant('PROFIL.ANREDE'), disabled: true },
                                titel: {label: this.translate.instant('PROFIL.TITEL'), disabled: false },
                                vorname: {label: this.translate.instant('PROFIL.VORNAME'), disabled: true },
                                nachname: {label: this.translate.instant('PROFIL.NACHNAME'), disabled: true },
                                verheirateterName: {label: this.translate.instant('PROFIL.VERHEIRATETERNAME'), disabled: false },
                            }
                        },
                        adresse: {
                            label: this.translate.instant('PROFIL.ADRESSE'),
                            edit: true,
                            selected: false,
                            subfields: {
                                headerlabel: this.translate.instant('PROFIL.ADRESSEAENDERN'),
                                nation: {label: this.translate.instant('PROFIL.NATION'), disabled: true },
                                provinz: {label: this.translate.instant('PROFIL.PROVINZ'), disabled: false },
                                ort: {label: this.translate.instant('PROFIL.ORT'), disabled: false },
                                plz: {label: this.translate.instant('PROFIL.POSTLEITZAHL'), disabled: false },
                                strasse: {label: this.translate.instant('PROFIL.STRASSE'), disabled: false },
                                hausnr: {label: this.translate.instant('PROFIL.HAUSNUMMER'), disabled: false },
                                weiler: {label: this.translate.instant('PROFIL.WEILER'), disabled: false },
                            }
                        },
                        email: {
                            label: this.translate.instant('PROFIL.EMAILADRESSE'),
                            disabled: true,
                            edit: true,
                            selected: false,
                            subfields: {
                                headerlabel: this.translate.instant('PROFIL.EMAILAENDERN'),
                                email: {label: this.translate.instant('PROFIL.EMAILADRESSE'), disabled: true },
                            }
                        },
                        telefon: {
                            label: this.translate.instant('PROFIL.TELEFON'),
                            edit: true,
                            selected: false,
                            subfields: {
                                headerlabel: this.translate.instant('PROFIL.KONTAKDATENAENDERN'),
                                telMobil: {label: this.translate.instant('PROFIL.MOBIL'), disabled: false },
                                telPrivat: {label: this.translate.instant('PROFIL.PRIVAT'), disabled: false },
                                telGeschaeftlich: {label: this.translate.instant('PROFIL.GESCHAEFTLICH'), disabled: false },
                                fax: {label: this.translate.instant('PROFIL.FAX'), disabled: false },
                                email: {label: this.translate.instant('PROFIL.EMAIL'), disabled: false },
                                // email2: {label: 'E-Mail 2', disabled: true },
                                internet: {label: this.translate.instant('PROFIL.INTERNET'), disabled: false },
                            }
                        },
                        gebdatum: {
                            label: this.translate.instant('PROFIL.GEBURTSDATUM'),
                            edit: false,
                            selected: false,
                        },
                        gebort: {
                            label: this.translate.instant('PROFIL.GEBURTSORT'),
                            edit: true,
                            selected: false,
                            subfields: {
                                headerlabel: this.translate.instant('PROFIL.DATENAENDERN'),
                                gebort: {label: this.translate.instant('PROFIL.GEBURTSORTAUSSERHALBITALIENNURSTAAT'), disabled: false },
                                nationalitaet_name: {label: this.translate.instant('PROFIL.NATIONALITAET'), disabled: false },
                                steuernummer: {label: this.translate.instant('PROFIL.STEUERNUMMER'), disabled: false },
                            }
                        },
                        geschlecht: {
                            label: this.translate.instant('PROFIL.GESCHLECHT'),
                            edit: false,
                            selected: false
                        },
                        nation: {
                            label: this.translate.instant('PROFIL.NATIONALITAET'),
                            edit: true,
                            selected: false
                        },
                        steuernummer: {
                            label: this.translate.instant('PROFIL.STEUERNUMMER'),
                            edit: true,
                            selected: false,
                        },
                        sprache: {
                            label: this.translate.instant('PROFIL.SPRACHE'),
                            edit: true,
                            selected: false,
                            subfields: {
                                headerlabel: this.translate.instant('PROFIL.DATENAENDERN'),
                                sprache: {label: this.translate.instant('PROFIL.SPRACHE'), disabled: false },
                            }
                        }
                }
            },
            {
                id: enuProfilMenu.Mitgliedsdaten,
                name: this.translate.instant('PROFIL.MITGLIEDSDATEN'),
                disabled: false,
                visible: false,
                fields: {
                        mitgliedsnummer: {
                            label: this.translate.instant('PROFIL.MITGLIEDSNUMMER'),
                            edit: false ,
                            selected: false,
                        },
                        sektion: {
                            label: this.translate.instant('PROFIL.SEKTION'),
                            edit: false,
                            selected: false,
                            subfields: {
                                headerlabel: this.translate.instant('PROFIL.SEKTIONAENDERN'),
                                geburtsort: {label: this.translate.instant('PROFIL.SEKTION'), disabled: false },
                            }
                        },
        
                        kategorie: {
                            label: this.translate.instant('PROFIL.KATEGORIE'),
                            edit: false,
                            selected: false
                        },
                        status: {
                            label: this.translate.instant('PROFIL.STATUS'),
                            edit: false,
                            selected: false
                        },
                        eintritt: {
                            label: this.translate.instant('PROFIL.EINTRITTGESAMTVEREIN'),
                            edit: false,
                            selected: false
                        },
                        jahreeintritt: {
                            label: this.translate.instant('PROFIL.JAHREABEINTRITT'),
                            edit: false,
                            selected: false
                        },
                        beitragszahlungen: {
                            label: this.translate.instant('PROFIL.ANZAHLBEITRAGSZAHLUNGEN'),
                            edit: false,
                            selected: false
                        },
                    }
            },
            {
                id: enuProfilMenu.meinkletterprofil,
                name: 'mein.kletterprofil',
                disabled: false,
                visible: false
            },
            {
                id: enuProfilMenu.Familie,
                name: this.translate.instant('PROFIL.FAMILIE'),
                disabled: false,
                visible: false,
                fields: {
                    familienmitglied: {
                        label: 'Familienmitglied',
                        edit: true ,
                        selected: false,
                        subfields: {
                            headerlabel: this.translate.instant('PROFIL.ERZIEHUNGSBERECHTIGTERAENDERN'),
                        }
                },
                    keinevorhanden: {
                        label: 'keine Familienmitglieder zugeordnet',
                    },
                }
            },
            {
                id: enuProfilMenu.Bergeerleben,
                name: this.translate.instant('PROFIL.BERGEERLEBEN'),
                disabled: false,
                visible: false,
                fields: {
                    anzahl: {
                        // label: 'Anzahl der Zusendungen "Bergeerleben"',
                        label: this.translate.instant('PROFIL.ANZAHLDERZUSENDUNGEN'),
                    },
                    mitgliedzeitung: {
                        label: 'Zeitung',
                        edit: true ,
                        selected: false,
                        subfields: {
                            headerlabel: 'Bergeerleben',
                        }
                    },
                }
            },
            {
                id: enuProfilMenu.MeineZahlungsarten,
                name: this.translate.instant('PROFIL.MEINEZAHLUNGSARTEN'),
                disabled: false,
                visible: false,
                fields: {
                    keinevorhanden: {
                        label: this.translate.instant('PROFIL.MITGLIEDKEINELASTSCHRIFT'),
                    },
                    bb: {
                        label: this.translate.instant('PROFIL.BANKBEZEICHUNG'),
                        edit: false,
                        selected: false
                    },
                    iban: {
                        label: this.translate.instant('PROFIL.IBAN'),
                        edit: false,
                        selected: false
                    },
                    bic: {
                        label: this.translate.instant('PROFIL.BIC'),
                        edit: false,
                        selected: false
                    },
                    kontoinhaber: {
                        label: this.translate.instant('PROFIL.KONTOINHABER'),
                        edit: false,
                        selected: false,
                        subfields: {
                            nachname: this.translate.instant('PROFIL.NACHNAME'),
                            vorname: this.translate.instant('PROFIL.VORNAME'),
                        }
                    },
                    adresse: {
                        label: this.translate.instant('PROFIL.ADRESSDATENBANK'),
                        edit: false,
                        selected: false,
                        subfields: {
                            strasse: this.translate.instant('PROFIL.STRASSE'),
                            plz: this.translate.instant('PROFIL.POSTLEITZAHL'),
                            ort: this.translate.instant('PROFIL.ORT'),
                            land: this.translate.instant('PROFIL.LAND'),
                        }
                    },
                    mandat: {
                        label: this.translate.instant('PROFIL.MANDATNUMMER'),
                        edit: false,
                        selected: false
                    },
                }
            },
            {
                id: enuProfilMenu.Mitgliedsbeitrag,
                name: this.translate.instant('PROFIL.MITGLIEDSBEITRAG'),
                disabled: false,
                visible: false,
                fields: {
                    keinevorhanden: {
                        label: this.translate.instant('PROFIL.KEINEZAHLUNGENVORHANDEN'),
                    },
                    eintritt: {
                        label: this.translate.instant('PROFIL.EINTRITTGESAMTVEREIN'),
                        edit: false,
                        selected: false
                    },
                    jahreeintritt: {
                        label: this.translate.instant('PROFIL.JAHREABEINTRITT'),
                        edit: false,
                        selected: false
                    },
                    beitragszahlungen: {
                        label: this.translate.instant('PROFIL.ANZAHLBEITRAGSZAHLUNGEN'),
                        edit: false,
                        selected: false
                    },
        
                }
            },
            {
                id: enuProfilMenu.Funktionen,
                name: this.translate.instant('PROFIL.FUNKTIONEN'),
                disabled: false,
                visible: false,
                fields: {
                    funktion: {
                        label: 'Funktion',
                        edit: false ,
                        selected: false,
                    },
                    keinevorhanden: {
                        label: 'keine Funktionen vorhanden',
                    },
                }
            },
            {
                id: enuProfilMenu.Qualifikationen,
                name: this.translate.instant('PROFIL.QUALIFIKATIONEN'),
                disabled: false,
                visible: false,
                fields: {
                    funktion: {
                        label: 'Qualifikationen',
                        edit: false ,
                        selected: false,
                    },
                    keinevorhanden: {
                        label: 'keine Qualifikationen vorhanden',
                    },
                }
            },
            {
                id: enuProfilMenu.Weiterbildung,
                name: this.translate.instant('PROFIL.WEITERBILDUNG'),
                disabled: false,
                visible: false,
                fields: {
                    faelligkeiterstehilfe: {
                        label: 'Fälligkeit Erste Hilfe (6 Jahre)',
                        edit: false ,
                        selected: false,
                    },
                    faelligkeitweiterbildungspflicht: {
                        label: 'Fälligkeit der Weiterbildungspflicht',
                        edit: false ,
                        selected: false,
                    },
                    eintaegig: {
                        label: 'Weiterbildung eintägig',
                        edit: false ,
                        selected: false,
                    },
                    zweitaegig: {
                        label: 'Weiterbildung zweitägig',
                        edit: false ,
                        selected: false,
                    },
                    halbtaegig: {
                        label: 'Weiterbildung halbtägig',
                        edit: false ,
                        selected: false,
                    },
                    erfuellungsgrad: {
                        label: 'Erfüllungsgrad der Weiterbildung',
                        edit: false ,
                        selected: false,
                    },
                    keinevorhanden: {
                        label: 'keine Weiterbildungen vorhanden',
                    },
                }
            },
            {
                id: enuProfilMenu.Kurse,
                name: this.translate.instant('PROFIL.KURSE'),
                extra: {
                    kurstyp: enuKursTyp.Kurs,
                    itemname: enuKursTyp.GetItemName(enuKursTyp.Kurs),
                },
                disabled: false,
                visible: false,
                fields: {
                    kurs: {
                        label: this.translate.instant('PROFIL.KURSE'),
                        edit: false ,
                        selected: false,
                    },
                    keinevorhanden: {
                        label: this.translate.instant('PROFIL.KEINEKURSEVORHANDEN'),
                    },
                }
        
            },
            {
                id: enuProfilMenu.Taetigkeiten,
                name: this.translate.instant('PROFIL.TAETIGKEITEN'),
                extra: {
                    kurstyp: enuKursTyp.Taetigkeit,
                    itemname: enuKursTyp.GetItemName(enuKursTyp.Taetigkeit),
                },
                disabled: false,
                visible: false,
                fields: {
                    taetigkeit: {
                        label: this.translate.instant('PROFIL.TAETIGKEIT'),
                        edit: false ,
                        selected: false,
                    },
                    keinevorhanden: {
                        label: this.translate.instant('PROFIL.KEINETAETIGKEITENVORHANDEN'),
                    },
                }
            },
            {
                id: enuProfilMenu.Ehrungen,
                name: this.translate.instant('PROFIL.EHRUNGEN'),
                disabled: false,
                visible: false,
                fields: {
                    ehrung: {
                        label: 'Ehrung',
                        edit: false ,
                        selected: false,
                    },
                    keinevorhanden: {
                        label: 'keine Ehrungen vorhanden',
                    },
                }
            },
            {
                id: enuProfilMenu.Dokumente,
                name: this.translate.instant('PROFIL.DOKUMENTE'),
                disabled: false,
                visible: false,
                fields: {
                    dokumente: {
                        label: 'Dokumente',
                        edit: false ,
                        selected: false,
                        subfields: {
                            headerlabel: 'Dokumente hinzufügen',
                            mitglied: {label: 'Mitglied', disabled: true },
                            typ: {label: 'Typ', disabled: false },
                            gueltigbis: {label: 'Gültig bis', disabled: false },
                            beschreibung: {label: 'Beschreibung', disabled: false },
                            datei: {label: 'Datei', disabled: false },
                        }
                    },
                    keinevorhanden: {
                        label: 'keine Dokumente vorhanden',
                    },
                }
            },
            {
                id: enuProfilMenu.Newsletter,
                name: this.translate.instant('PROFIL.NEWSLETTER'),
                disabled: false,
                visible: false,
                fields: {
                    newsletter: {
                        label: 'Newsletter',
                        edit: true ,
                        selected: false,
                        subfields: {
                                headerlabel: 'Newsletter an/abmelden',
                        }
                    },
                    newslettertext: {
                        label: ' per E-Mail informiert bleiben',
                    },
                    abonniert: {
                        label: 'Du erhältst unseren Newsletter',
                    },
                    nichtabonniert: {
                        label: 'Du erhältst keinen Newsletter',
                    },
                }
            },
            {
                id: enuProfilMenu.Mitgliedskarte,
                name: this.translate.instant('PROFIL.MITGLIEDSKARTE'),
                disabled: false,
                visible: false,
                fields: {
                    mitgliedskarte: {
                        label: this.translate.instant('PROFIL.MITGLIEDSKARTE'),
                        edit: true,
                        selected: false,
                        subfields: {
                                headerlabel: 'Mitgliedskart downloaden',
                        }
                    },
                }
            },
            {
                id: enuProfilMenu.Passwortaendern,
                name: this.translate.instant('PROFIL.PASSWORTAENDERN'),
                disabled: false,
                visible: false,
            },
            // {
            //     id: '7',
            //     name: 'Benutzerprofil löschen',
            //     disabled: false,
            // },
            {
                id: enuProfilMenu.Abmelden,
                name: this.translate.instant('PROFIL.ABMELDEN'),
                disabled: false,
                visible: true,
            },
        ];

       return profilmenuMitglied; 
    }


}
