<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" crossorigin="anonymous">
<div class="portalRoot">
  <router-outlet></router-outlet>
  
  <dx-toast
    [(visible)]="updateLiveReloadToast"
    type="custom"
    displayTime="60000"
    position="bottom"
    width="650"
    style="background-color: black !important;"
    [hideOnOutsideClick]="false"
    [hideOnParentScroll]="false"
  >
    <div *dxTemplate="let data of 'content'">
      <div style="display: flex; align-items: center; justify-content: center; margin-top: 5px;">
        <span>{{this.updateLiveReloadMessage}}</span>
      </div>
      <dx-button width="90%" text="{{'JETZTNEULADEN' | translate }}" style="margin-top: 10px; margin-bottom: 10px; color: white; margin-left: 5%" stylingMode="outlined" (onClick)="onClickReload($event)"></dx-button>
    </div>
  </dx-toast>
</div>